import {isValid} from "@electronicpartnerio/ep-ui";
import {api} from "../helper/api";
import {IErrorResponse} from "./interface";
import {API_CONTACT, API_CONTACT_CHALLENGE, HIDDEN_CLASS_NAME} from "../constant";
import { CaptchaElement } from "@electronicpartnerio/ep-ui/dist/src/component/molecule/form/captcha/interface";

const contact = () => {
    let verificationKey = null;
    const {request} = api();
    const apiEndpoint = (window as any).EP.api;
    const form = document.getElementById('contact-form')!;
    const captcha = document.querySelector('ep-form-captcha')! as CaptchaElement;
    const notificationSuccess = document.getElementById('notification-success')!;
    const notificationError = document.getElementById('notification-error')!;
    const notificationErrorMsg = notificationError.querySelector('p')!;
    const rawErrorMsg = notificationErrorMsg.innerHTML;
    const contactOptions = document.getElementById('kontaktOptions')!;
    const email = document.getElementById('email')!;
    const telefon = document.getElementById('telefon')!;
    const uid = document.getElementById('uid')! as HTMLInputElement;
    const allInputFields = form.querySelectorAll('ep-form-select, ep-form-input-text, ep-form-input-email, ep-form-input-tel, ep-form-checkbox, ep-form-textarea')

    const handleFormSubmit = async (e: Event) => {
        e.preventDefault();

        if (!verificationKey || !isValid(allInputFields)) {
            captcha.toggleErrorMessage(!!verificationKey);
            return
        }

        const formData: { [key: string]: string | boolean | string[] } = {
            uid: uid.value,
            captcha: verificationKey,
        }

        for (let i = 0; i < allInputFields.length; i++) {
            const {name, value} = allInputFields[i] as HTMLInputElement
            if (name === 'consent') {
                formData[name] = value === '1';
            } else if (name === 'channel') {
                formData[name] = value.split(',');
            } else {
                formData[name] = value;
            }
        }

        // @ts-ignore
        const {data, status} = await request(apiEndpoint + API_CONTACT, JSON.stringify(formData), 'POST')
            .catch((e) => console.log(e));

        if (status < 400) {
            showSuccess()
        } else {
            showError(data)
        }
    }

    const handleOptionsChange = (e: Event) => {
        const selectedContactOptions: string[] = (e as CustomEvent).detail.split(',');

        if (selectedContactOptions.includes('email')) {
            email.setAttribute('required', '');
        } else {
            email.removeAttribute('required');
        }


        if (selectedContactOptions.includes('phone')) {
            telefon.setAttribute('required', '');
        } else {
            telefon.removeAttribute('required');
        }
    }

    const scrollIntoView = (el: HTMLElement) => el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
    });

    const showSuccess = () => {
        notificationSuccess.classList.remove(HIDDEN_CLASS_NAME);
        notificationError.classList.add(HIDDEN_CLASS_NAME);
        form.classList.add(HIDDEN_CLASS_NAME);
        scrollIntoView(notificationSuccess)
    }

    const showError = (data: IErrorResponse) => {
        let msg = rawErrorMsg;
        if (data.msg) {
            msg = `${data.msg}<br />${msg}`;
        }
        if (data.code) {
            msg += `<br />Error: ${data.code}`;
        }
        notificationErrorMsg.innerHTML = msg;
        notificationError.classList.remove(HIDDEN_CLASS_NAME);
        notificationSuccess.classList.add(HIDDEN_CLASS_NAME);
        scrollIntoView(notificationError)
    }

    const setVerificationKey = (e: CustomEvent) => {
        verificationKey = e.detail.payload;
    }

    captcha.onVerified = setVerificationKey;
    captcha.setAttribute('api', apiEndpoint + API_CONTACT_CHALLENGE);

    form.addEventListener('submit', handleFormSubmit);
    contactOptions.addEventListener('change', handleOptionsChange);
}
export default contact