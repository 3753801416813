import {HTMLElement, InputEvent} from "happy-dom";
import {api} from "../helper/api";
import {ISuggestion} from "./interface";
import {renderAutocompleteSuggestion, renderAutocompleteSuggestions} from "./tpl";
import {redirectToSearch} from "./helper/redirectToSearch";

import {API_ZIP_SEARCH, URL_PARAM_LAT, URL_PARAM_LNG, URL_PARAM_SEARCH} from "../constant";

const consultantSearch = () => {
    const {request} = api();
    const apiEndpoint = (window as any).EP.api;
    const input  = document.getElementById('consultant-search-input') as HTMLInputElement
    const suggestionTarget  = document.getElementById('consultant-search-suggestions') as HTMLDivElement;
    const form = document.getElementById('consultant-search') as HTMLFormElement;
    const searchTarget = form.action;
    let suggestions: ISuggestion[] | string;

    const handleAutoComplete = async (e: InputEvent) => {
        const {value} = e.currentTarget as unknown as HTMLInputElement;
        const triggerAPI = value.length >= 3;
        if(triggerAPI) {
            const {data, ok, status} = await request(`${apiEndpoint}${API_ZIP_SEARCH}/${encodeURIComponent(value.trim())}`, null, 'GET');
            if(ok && status < 400) {
                suggestions = data;
                renderSuggestions()
            }
        }
        suggestionTarget.classList.toggle('hidden', !triggerAPI)
    }

    const renderSuggestions = () => {
        let listElements: string[];

        if(typeof suggestions === 'string') {
            listElements= [`<li>${suggestions}</li>`];
        } else {
            listElements = suggestions.map(renderAutocompleteSuggestion);
        }

        suggestionTarget.innerHTML = renderAutocompleteSuggestions(listElements.join(''))
    }

    const handleSuggestionClick = (e: MouseEvent) => {
        e.stopPropagation();
        const {target}  = e;
        const {lat, lng} = (target as unknown as HTMLElement)?.dataset;

        if(lat && lng) {
            proceedSearchFlow(lat, lng)
        }
    }

    const proceedSearchFlow = (lat: string, lng: string) => {
        const suggestion =  (suggestions as ISuggestion[]).find((s) => s.lat === Number(lat) && s.lng === Number(lng))!;

        input.value = `${suggestion.zip} ${suggestion.city}`;

        redirectToSearch(searchTarget, input.value , lat, lng);
    }

    const handleSubmitForm = (e: Event) => {
        e.preventDefault();
        const {value} = input;

        redirectToSearch(searchTarget, value);
    }

    const setInputValue = () => {
        const urlObj = new URL(window.location.href);
        const search = urlObj.searchParams.get(URL_PARAM_SEARCH);

        search && (input.value = search)
    }

    // @ts-ignore
    input.addEventListener('input', handleAutoComplete);
    form.addEventListener('submit', handleSubmitForm);
    suggestionTarget.addEventListener('click', handleSuggestionClick);
    setInputValue()
}

export default consultantSearch;