import {renderMarkerPopup, renderSearchResultTeaser, renderSearchSuggestionTeaser} from "./tpl";
import {api} from "../helper/api";
import {HTMLElement} from "happy-dom";
import {centerMapToMarker, clearLayers, map, mapMarker, renderMapIcon} from "../helper/map";
import {Layer, Map} from "leaflet";
import {IConsultant, ISuggestion} from "./interface";
import {
    API_CONSULTANT_SEARCH,
    API_STATIC_MEMBER_JSON,
    API_ZIP_SEARCH, HIDDEN_CLASS_NAME, URL_PARAM_LAT,
    URL_PARAM_LNG,
    URL_PARAM_SEARCH
} from "../constant";
import {updateSearchParams} from "./helper/redirectToSearch";

const consultantSearchResult = async () => {
    const {request} = api();
    const apiEndpoint = (window as any).EP.api;
    const memberJsonEndpoint = (window as any).EP.mje || '';
    let search: string = '';
    let llMap: Map;
    let staticMemberData: unknown;
    let markers: Layer[] = [];

    const searchForm = document.getElementById('consultant-search') as HTMLFormElement;
    const notifications = document.getElementsByClassName('consultant-search-notification');
    const resultWrapper = document.getElementById('consultant-search-result') as HTMLDivElement;
    const resultsWrapper = document.getElementById('consultant-search-results') as HTMLDivElement;
    const resultsTarget = document.getElementById('consultant-search-results-target') as HTMLDivElement;
    const suggestionsWrapper = document.getElementById('consultant-search-result-suggestions') as HTMLDivElement;
    const suggestionsTarget = document.getElementById('consultant-search-result-suggestions-target') as HTMLDivElement;

    const getStaticJSON = async () => {
        const {data, ok, status} = await request(memberJsonEndpoint + API_STATIC_MEMBER_JSON, null, 'GET');

        if (ok && status < 400) {
            staticMemberData = data;
            toggleNotifications(true);
        } else {
            toggleNotifications(false, 'error');
        }
    }

    const triggerSearchConsultant = async ({lat, lng}: {[key: string]: string}) => {
        const {data, ok, status} = await request(`${apiEndpoint}${API_CONSULTANT_SEARCH}/${lat}/${lng}`, null, 'GET');

        if (ok && status < 400) {
            renderResultList(data);
            toggleNotifications(true);
        } else {
            toggleNotifications(false, 'error');
        }
    }

    const triggerSearchAddress = async () => {
        const {data, ok, status} = await request(`${apiEndpoint}${API_ZIP_SEARCH}/${encodeURIComponent(search.trim())}`, null, 'GET');

        if (ok && status < 400) {
            switch (data.length) {
                case 1:
                    const {lat, lng} = data[0];
                    updateSearchParams(search, lat, lng)
                    triggerSearchConsultant({lat, lng});
                    return;
                case undefined:
                case 0:
                    toggleNotifications(false, 'info');
                    return;
                default:
                    renderSuggestionList(data);
                    toggleNotifications(true);
                    return;
            }
        } else {
            toggleNotifications(false, 'error');
        }
    }

    const renderResultList = (data: IConsultant[]) => {
        suggestionsWrapper.classList.add(HIDDEN_CLASS_NAME);
        resultsWrapper.classList.remove(HIDDEN_CLASS_NAME);
        showAside(resultsWrapper);

        markers.length && clearLayers(llMap, markers);
        markers = [];

        const renderedList = data.map((obj: IConsultant) => {
            const extData = {
                ...obj,
                ...staticMemberData[obj.uid]
            }
            markers.push(renderMapIcon(extData, llMap));

            return renderSearchResultTeaser(extData);
        })
        resultsTarget.innerHTML = renderedList.join('\n');

        const teaserLinks = resultsTarget.querySelectorAll('.result-link, .result-button') as unknown as HTMLCollectionOf<HTMLLinkElement>;

        // @ts-ignore
        for (let teaser of teaserLinks) {
            teaser.addEventListener('click', handleResultTeaserClick)
        }
        centerMapToMarker(llMap, markers);
    }

    const renderSuggestionList = (data: ISuggestion[]) => {
        resultsWrapper.classList.add(HIDDEN_CLASS_NAME);
        suggestionsWrapper.classList.remove(HIDDEN_CLASS_NAME);

        const renderedList = data.map(renderSearchSuggestionTeaser);
        suggestionsTarget.innerHTML = renderedList.join('\n');
        showAside(suggestionsWrapper);
    }

    const handleSuggestionClick = (e: MouseEvent) => {
        e.stopPropagation();
        const target = e.target as unknown as HTMLElement;
        const {lat, lng} = target.dataset;

        updateSearchParams(search, lat, lng)
        triggerSearchConsultant({lat, lng})
    }

    const handleResultTeaserClick = (e: MouseEvent) => {
        e.preventDefault();
        const {uid} = (e.currentTarget as HTMLDivElement).dataset;
        const marker = document.querySelector(`img[alt="${uid}"]`) as unknown as HTMLElement;
        // @ts-ignore types are outdated
        const selectedMarker = markers.find((m: Layer) => m.options.alt === uid);
        selectedMarker.openPopup();

        centerMapToMarker(llMap, [selectedMarker], 12);
        searchForm.scrollIntoView();
    }

    const handleUrlChanged = () => {
        const urlObj = new URL(window.location.href);
        const lat = urlObj.searchParams.get(URL_PARAM_LAT);
        const lng = urlObj.searchParams.get(URL_PARAM_LNG);
        search = urlObj.searchParams.get(URL_PARAM_SEARCH);

        if (lat && lng) {
            triggerSearchConsultant({lat, lng})
        } else if (search) {
            triggerSearchAddress()
        }
    }

    const showAside = (child: HTMLDivElement) => child.parentElement!.classList.remove(HIDDEN_CLASS_NAME)

    const renderMap = () => {
        // center for startup to germany - will be zoomed after search result
        llMap = map(51.1642292, 10.4541194, 7)
    }



    const toggleNotifications = (success, type?: string | undefined) => {
        resultWrapper.classList.toggle(HIDDEN_CLASS_NAME, !success);
        // @ts-ignore
        for (let notification of notifications) {
            const force = !success && type && notification.classList.contains(type);
            notification.classList.toggle(HIDDEN_CLASS_NAME, !force);
        }
    }

    window.addEventListener('popstate', handleUrlChanged);
    suggestionsTarget.addEventListener('click', handleSuggestionClick);

    getStaticJSON()
    renderMap()
    handleUrlChanged();
};

export default consultantSearchResult;