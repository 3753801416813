import iframeResize from '@iframe-resizer/parent'

const iframe = () => {
    const iframes = document.getElementsByClassName('iframe') as HTMLCollectionOf<HTMLIFrameElement>;

    for (let i = 0; i< iframes.length; i++) {
        iframeResize({
            license: '1jy4dww5qzv-s54r73oxcn-v59f4kfgfz',
            waitForLoad: false,
        }, iframes[i]);
    }
}

export default iframe;