import * as L from "leaflet";
import {Layer, Map} from "leaflet";
import {IConsultant} from "../consultant-search/interface";
import {renderMarkerPopup} from "../consultant-search/tpl";

export const mapIcon = () =>  L.icon({
    iconUrl: '/static/map-marker.svg',
    iconSize: [32, 49],
    iconAnchor: [16, 48],
    popupAnchor: [0, -49],
});

export const mapMarker = (lat: number, lng: number, alt: string = '') => {
    const icon = mapIcon();

    return L.marker([lat, lng], { icon, alt});
}

export const map = (lat: number, lng: number, zoom: number = 7) => {
    const options: L.MapOptions = {
        preferCanvas: true,
        minZoom: 4,
    };

    const map = L.map('map-target', options)
        .setView([lat, lng], zoom );

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    })
        .addTo(map);

    return map;

}

export const centerMapToMarker = (llMap: L.Map, markers:  Layer[], lvl?: number) => {
    // @ts-ignore
    const group: any = new L.featureGroup(markers);
    const boundsOptions: L.FitBoundsOptions = {};
    lvl && (boundsOptions.maxZoom = lvl);

    llMap
        .invalidateSize()
        .fitBounds(group.getBounds(), boundsOptions);
}

export const clearLayers = (map: L.Map, markers:  Layer[]) => markers.forEach((marker) => map.removeLayer(marker));

export const renderMapIcon = (obj: IConsultant, llMap: Map) => {
    const popup = renderMarkerPopup(obj);
    const alt = `${obj.name} - ${obj.uid}`;

    return mapMarker(obj.lat, obj.lng, alt)
        .addTo(llMap)
        .bindPopup(popup);
}