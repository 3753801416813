import {URL_PARAM_LAT, URL_PARAM_LNG, URL_PARAM_SEARCH} from "../../constant";

export const redirectToSearch = (target: string, search: string, lat?: string, lng?: string) => {
    if(location.href.includes(target)) {
        updateSearchParams(search, lat, lng);
        window.dispatchEvent(new Event('popstate'));
    } else {
        const paramObj: any = {search}
        lat && (paramObj[URL_PARAM_LAT] = lat);
        lng && (paramObj[URL_PARAM_LNG] = lng);
        const params = new URLSearchParams(paramObj).toString();

        window.location.href = `${target}?${params}`
    }
}

export const updateSearchParams = (search: string, lat?: string, lng?: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set(URL_PARAM_SEARCH, search);

    lat ? url.searchParams.set(URL_PARAM_LAT, lat) : url.searchParams.delete(URL_PARAM_LAT);
    lng ? url.searchParams.set(URL_PARAM_LNG, lng) : url.searchParams.delete(URL_PARAM_LNG);

    window.history.pushState(null, '', url.toString());
}