import "../styles/index.scss"

/**
 * @constructor
 */
(() => {
    import('./icon-loader')
        .then((obj) => obj.default());

    const consultantSearchEl = document.getElementById('consultant-search');
    consultantSearchEl && import('./consultant-search/search')
            .then((obj) => obj.default());

    const consultantSearchResultEl = document.getElementById('consultant-search-result');
    consultantSearchResultEl && import('./consultant-search/result')
            .then((obj) => obj.default());

    const consultantDetailEl = document.getElementById('map-stripe-target');
    consultantDetailEl && import('./consultant-detail/index')
            .then((obj) => obj.default());

    const aboutWendepunktEl = document.getElementById('map-about-wendepunkt');
    aboutWendepunktEl && import('./about-wendepunkt/index')
            .then((obj) => obj.default());

    const iframe = document.getElementById('solar-calculator');
    iframe && import('./iframe')
            .then((obj) => obj.default());

    const contactEl = document.getElementById('contact-form');
    contactEl && import('./contact')
        .then((obj) => obj.default());

    const introEl = document.getElementById('intro');
    introEl && import('./intro')
        .then((obj) => obj.default());

    setTimeout(() => document.body.classList.remove('preload'), 1);
})()
