import {IConsultant, IConsultantPicture, ISuggestion} from "./interface";
import {formatDistance} from "./helper/formatDistance";

export const renderAutocompleteSuggestions = (str: string) => `<div><ul>${str}</ul></div>`
export const renderAutocompleteSuggestion = (obj: ISuggestion) => `<li><a href="javascript:void(0)" data-lat="${obj.lat}" data-lng="${obj.lng}">${obj.zip} ${obj.city}</a></li>`
export const renderSearchResultTeaser = (obj: IConsultant) => `
    <li class="teaser result">
        <a class="result-link" href="${obj.url}" data-uid="${obj.name} - ${obj.uid}">
            <div class="teaser-image${obj.picture ? '' : ' hidden'}">
                ${obj.picture ? renderPicture(obj.picture) : ''}
            </div>
            <div class="address">
                <h5>
                    ${obj.name}
                </h5>
                <span>
                    ${obj.street} ${obj.streetNumber}<br/>
                    ${obj.zip} ${obj.city}<br/>
                    <small>Entfernung: ${formatDistance(obj.distance)}km</small>
                </span>
            </div>
        </a>
        <div class="buttons">
            <a class="btn btn-small btn-primary-inverse" href="${obj.url}">Zum Partner</a>
            <a class="btn btn-small btn-primary-inverse result-button" href="javascript:void(0)" data-uid="${obj.name} - ${obj.uid}">Auf Karte anzeigen</a>
        </div>
    </li>
`;

export const renderMarkerPopup = (obj: IConsultant) => `
        <a class="teaser result" href="${obj.url}">
            <div class="teaser-image${obj.picture ? '' : ' hidden'}">
                ${obj.picture ? renderPicture(obj.picture) : ''}
            </div>
            <div class="address">
                <h5>
                    ${obj.name}
                </h5>
                <span>
                    ${obj.street} ${obj.streetNumber}<br/>
                    ${obj.zip} ${obj.city}<br/>
                    <small>Entfernung: ${formatDistance(obj.distance)}km</small>
                </span>
            </div>
        </a>
`

export const renderSearchSuggestionTeaser = (obj: ISuggestion) => `
    <li>
        <a class="teaser suggestion" href="javascript:void(0)" data-lat="${obj.lat}" data-lng="${obj.lng}">
            ${obj.zip} ${obj.city}
        </a>
    </li>
`

export const renderPicture = (picture: IConsultantPicture[]) => {
    const cnt = picture.map((pic) => {

        if(pic.img) {
            return `<img src="${pic.img.src}" alt="${pic.img.alt}" loading="${pic.img.loading}">`
        }

        return `<source type="${pic.source.type}" srcset="${pic.source.srcset}">`
    })

    return `<picture>${cnt.join('')}</picture>`;
}