import {Map} from "leaflet";
import {api} from "../helper/api";
import {centerMapToMarker, map, mapMarker, renderMapIcon} from "../helper/map";
import {IConsultant} from "../consultant-search/interface";
import {API_CONSULTANT_SEARCH, API_STATIC_MEMBER_JSON} from "../constant";
import {renderMarkerPopup} from "../consultant-search/tpl";

const consultantLocation = async () => {
    const {request} = api();
    const apiEndpoint = (window as any).EP.api;
    const memberJsonEndpoint = (window as any).EP.mje || '';
    let llMap: Map;

    const requestLocations = async () => {
        const [apiData, jsonData,] = await Promise.all([
            request(`${apiEndpoint}${API_CONSULTANT_SEARCH}/0/0`, null, 'GET'),
            request(memberJsonEndpoint + API_STATIC_MEMBER_JSON, null, 'GET')
        ])

        if (apiData.ok && apiData.status < 400) {
            renderMapIcons(apiData.data, jsonData.data)
        }
    };

    const renderMap = () => {
        llMap = map(51.1642292, 10.4541194, 7)
    }

    const renderMapIcons = (apiData: IConsultant[], jsonData: unknown) => {
        const markers = apiData.map((location) => renderMapIcon({
            ...location,
            ...jsonData[location.uid],
        }, llMap));

        centerMapToMarker(llMap, markers);
    }

    renderMap();
    await requestLocations();
};

export default consultantLocation;